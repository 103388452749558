import { dom, library } from '@fortawesome/fontawesome-svg-core'

import {
  faHome,
  faShip,
  faUsers,
  faUser,
  faShieldAlt,
  faFilter,
  faCheck,
  faFileSignature,
  faListAlt,
  faUserPlus,
  faStamp,
  faFileContract,
  faUserClock,
  faSignInAlt,
  faSignOutAlt,
  faEdit,
  faFlag,
  faAnchor,
  faTimes,
  faSearch,
  faTasks,
  faSitemap,
  faCommentDots,
  faCheckDouble,
  faBan,
  faThumbtack,
  faSync,
  faLockOpen,
  faUserShield,
  faPrint,
  faLock,
  faBuilding,
  faCog,
  faMoneyBill,
  faHandHoldingUsd,
  faBolt,
  faDolly,
  faForward,
  faDollarSign,
  faMoneyBillWave,
  faHistory,
  faFileInvoiceDollar,
  faFastForward,
  faVoteYea,
  faPlane,
  faSortDown,
  faFileDownload,
  faNewspaper,
  faCalendarAlt,
  faHandPaper,
  faSave,
  faAddressBook,
  faIndustry,
  faUserTie,
  faUserCheck,
  faFingerprint,
  faFileImport,
  faSyncAlt,
  faCocktail,
  faUmbrellaBeach,
  faBullhorn,
  faFolderOpen,
  faWarehouse,
  faList,
  faUsersCog,
  faBox,
  faTools,
  faChartBar,
  faChartArea,
  faExchangeAlt,
  faTimesCircle,
  faCheckCircle,
  faInfoCircle,
  faInbox,
  faStar,
  faEnvelope,
  faBookmark,
  faChevronLeft,
  faChevronRight,
  faTags,
  faEllipsisH,
  faEye,
  faSpinner,
  faStepForward,
  faBug,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faEnvelopeOpenText,
  faUserEdit,
  faExclamationCircle,
  faBell
} from '@fortawesome/free-solid-svg-icons'

import {
  faStar as farStar,
  faBookmark as farBookmark,
  faBell as farBell,
  faCheckSquare
} from '@fortawesome/free-regular-svg-icons'

import { faBlackTie } from '@fortawesome/free-brands-svg-icons'

dom.watch()

library.add(
  faHome,
  faShip,
  faUsers,
  faUser,
  faShieldAlt,
  faFilter,
  faCheck,
  faFileSignature,
  faListAlt,
  faUserPlus,
  faStamp,
  faFileContract,
  faUserClock,
  faSignInAlt,
  faSignOutAlt,
  faEdit,
  faFlag,
  faAnchor,
  faTimes,
  faSearch,
  faTasks,
  faSitemap,
  faCommentDots,
  faCheckDouble,
  faBan,
  faThumbtack,
  faSync,
  faLockOpen,
  faUserShield,
  faPrint,
  faLock,
  faBuilding,
  faCog,
  faMoneyBill,
  faHandHoldingUsd,
  faBolt,
  faDolly,
  faForward,
  faDollarSign,
  faMoneyBillWave,
  faHistory,
  faFileInvoiceDollar,
  faFastForward,
  faVoteYea,
  faPlane,
  faSortDown,
  faFileDownload,
  faNewspaper,
  faCalendarAlt,
  faHandPaper,
  faSave,
  faUserCheck,
  faAddressBook,
  faIndustry,
  faBlackTie,
  faUserTie,
  faFingerprint,
  faFileImport,
  faSyncAlt,
  faCocktail,
  faUmbrellaBeach,
  faBullhorn,
  faFolderOpen,
  faWarehouse,
  faList,
  faUsersCog,
  faBox,
  faChartArea,
  faExchangeAlt,
  faTools,
  faChartBar,
  faTimesCircle,
  faCheckCircle,
  faInfoCircle,
  faInbox,
  faStar,
  farStar,
  faEnvelope,
  faBookmark,
  farBookmark,
  faChevronLeft,
  faChevronRight,
  faTags,
  faEllipsisH,
  faEye,
  faSpinner,
  faStepForward,
  faBug,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCheckSquare,
  faEnvelopeOpenText,
  faUserEdit,
  faExclamationCircle,
  faBell,
  farBell
)
